export const menus = [
  {
    id: 'home',
    label: '首页',
  },
  {
    id: 'service-introduce',
    label: '服务介绍',
  },
  {
    id: 'platform-advance',
    label: '平台优势',
  },
  {
    id: 'customer-case',
    label: '客户评价',
  },
  {
    id: 'charge-standard',
    label: '收费标准',
  },
  {
    id: 'about-us',
    label: '关于我们',
  },
];

export const serviceSettingList = [
  {
    prefix: '帮您完成',
    highLight: '海外雇佣1',
    color: '#FC7A58',
    suffix: '，让您专注拓展全球业务',
    width: 700,
    content:
      '通过DG.hire的雇佣解决方案，无需您注册海外雇佣实体，我们处理整个本地雇佣流程，负责所有合规性、工资和人力资源管理，而员工则像团队其他成员一样为您的企业工作',
    tags: ['严密的雇佣合同', '简化的办理流程', '完善的合规担保'],
    img: 'https://video.reta-inc.com/image/default/8294894F3D6644648A9CEA8A9A8727C6-6-2.png',
    followImg:
      'https://video.reta-inc.com/image/default/4B556B45F00740F2A0CF813B12C6824E-6-2.png',
    followClass: 'follow1',
  },
  {
    prefix: '我们支持全球范围内的',
    highLight: '灵活用工1',
    color: '#7A44FF',
    suffix: '',
    width: 600,
    content:
      '为海外企业和员工提供雇佣的法律保障。支持长期、项目制签约等方式',
    tags: ['安全合规的合同', '便捷的操作流程', '清晰的审计记录'],
    img: 'https://video.reta-inc.com/image/default/06D6739202A34F26A5B012D910788D5A-6-2.png',
    followImg:
      'https://video.reta-inc.com/image/default/DA1161B9D7444FFABEAFD8F1CA6018B1-6-2.png',
    followClass: 'follow2',
    reverse: true,
  },
  {
    prefix: '我们为您完成150+国家的',
    highLight: '薪酬服务1',
    color: '#386AFF',
    suffix: '',
    width: 630,
    content:
      '通过DG.hire平台可以简化支付流程，减少为支付团队薪酬而拼凑供应商和平台的时间，为您的整个团队提供便捷高效的服务',
    tags: ['简洁的支付流程', '合规的薪酬支付', '全面的账单管理'],
    img: 'https://video.reta-inc.com/image/default/619D5094A47345248DCFD3B1F8A1EA15-6-2.png',
    followImg:
      'https://video.reta-inc.com/image/default/B7D29B3282144F57BFD6E5A15B6475B2-6-2.png',
    followClass: 'follow3',
  },
  {
    prefix: '全球人力资源领域的',
    highLight: '合规咨询',
    color: '#FF8B37',
    suffix: '服务1',
    width: 630,
    content:
      '如果您有合规管理咨询的诉求，我们能够匹配专业的法律专家进行答疑解惑，为您的出海业务做好安全合规保障',
    tags: ['全球劳动法咨询', '法律法规培训', '员工合规管理'],
    img: 'https://video.reta-inc.com/image/default/2D7C7FDCAF4541C2BCDE86082BE36BE0-6-2.png',
    followImg:
      'https://video.reta-inc.com/image/default/87FD7DEBD61F482CB7C426E96C1766CA-6-2.png',
    followClass: 'follow4',
    reverse: true,
  },
  {
    prefix: '作为全球猎头为您提供',
    highLight: '人才招聘',
    color: '#3C56E0',
    suffix: '服务',
    width: 630,
    content:
      '无论您的人才需求是什么，我们团队都会用专业知识和经验来帮助您招募最优秀最适合的人才',
    tags: ['高管寻访', '专业人才招聘', '人才库管理'],
    img: 'https://video.reta-inc.com/image/default/85BD68A3257F4B7BABF515BB5F74C0CF-6-2.png',
    followImg:
      'https://video.reta-inc.com/image/default/4205474746204B16896B6F844AC11871-6-2.png',
    followClass: 'follow5',
  },
];
